import { Children, ReactNode } from "react"

type RoundedImageParams = {
    children : ReactNode,
    roundedLevel? : "rounded-sm" | "rounded-lg" | "rounded-xl" | "rounded-2xl" | "rounded-md" | "rounded-3xl" | "rounded-full"
}

function RoundedImage({children, roundedLevel = "rounded-md"} : RoundedImageParams) {


    const className : string = `rounded-${roundedLevel}`

    return <div className={`${roundedLevel} overflow-hidden w-auto` }>
        {children}
    </div>
}

export default RoundedImage