import { Link } from "react-router-dom"

function Footer() {
    return <div className="w-100 py-3 bg-background-gray">
        <div className="section-container flex px-6 gap-10 flex-col">
            <div className="flex gap-10 justify-between md:flex-row flex-col md:items-start">
                <div className="flex  flex-col  gap-3">
                    <p className="text-medium font-ligh font-elegant">Reformas</p>
                    <nav className="flex flex-col gap-3">
                        <Link to={"/reformas-de-baño"}><p className="text-paragraph font-light cursor-pointer hover:underline">Reformas de baños</p></Link>
                        <Link to={"/reformas-de-cocina"}><p className="text-paragraph font-light cursor-pointer hover:underline">Reformas de cocinas</p></Link>
                        <Link to={"/reformas-de-piso"}><p className="text-paragraph font-light cursor-pointer hover:underline">Reformas de pisos</p></Link>
                    </nav>
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-medium font-ligh font-elegant">Sobre nosotros</p>
                    <nav className="flex flex-col gap-3">
                        <Link to={"/quienes-somos"}><p className="text-paragraph font-light cursor-pointer hover:underline">¿Quienes somos?</p></Link>
                        <Link to={"/"}><p className="text-paragraph font-light cursor-pointer hover:underline">Pagina principal</p></Link>
                    </nav>
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-medium font-ligh font-elegant">Contacta con nosotros</p>
                    <nav className="flex flex-col gap-3">
                        <Link to={"/contacto"}><p className="text-paragraph font-light cursor-pointer hover:underline">Contacto</p></Link>
                        <Link to={"/contacto"}><p className="text-paragraph font-light cursor-pointer hover:underline">Solicita un presupuesto</p></Link>
                    </nav>
                </div>
            </div>
            <p className="font-light text-end">Valcri reformas SA &copy; Todos los derechos reservados </p>
        </div>
    </div>
}

export default Footer