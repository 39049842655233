import { ReactNode, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { BsFillTriangleFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";


type OptionsLinkParams= {
    text : string,
    navegationElements : NavegationElement[]
}

type NavegationElement = {
    to : string,
    text : string
}

function OptionsLink({navegationElements, text} : OptionsLinkParams) {

    const [show, setShow] = useState(false)



    const handleTextMouseEnter = () => {
        if (!show) setShow(true)
    }

    const handleTextMouseLeave = () => {
        if (show) setShow(false)
    }

    return <div onMouseLeave={handleTextMouseLeave} className="relative">
        <div className="flex gap-0.5 items-center">
            <p onMouseEnter={handleTextMouseEnter} className="cursor-pointer items-center" >{text}</p>
            {show ? <IoMdArrowDropup className="text-white text-paragraph h-fit pt-0.5 lg:pt-1"></IoMdArrowDropup>
            : <IoMdArrowDropdown className="text-white text-paragraph h-fit pt-0.5 lg:pt-1"></IoMdArrowDropdown>}


        </div>
        {show ? <div className="lg:absolute  w-44 top-full">
            <div className="h-3">
            </div>
            <div className="flex flex-col relative rounded-md bg-background-dark lg:bg-valcri-blue gap-3 p-4">
                <BsFillTriangleFill className="absolute bottom-[96.5%] left-[0.5px] text-small text-background-dark lg:text-valcri-blue"></BsFillTriangleFill>
                {navegationElements.map(({text, to}) => 
                    <Link  to={to}>
                        <p className="hover:underline">{text}</p>
                    </Link>
                )}
            </div>
            
        </div> : null}
    </div>
}

export default OptionsLink