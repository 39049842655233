import { ReactNode } from "react"
import { Link } from "react-router-dom"

type ButtonStyles = "green" | "transparent" | "red" | "blue"


type ButtonParams = {
    text : string,
    style? : ButtonStyles,
    urlTo? : string,
    className? : string
}

function Button({style = "transparent", text, urlTo, className} : ButtonParams) {

    const sharedClasses = `flex justify-center items-center py-2 px-7 rounded-md cursor-pointer hover:bg-opacity-40 font-light ${className}` 

    const button : ReactNode = 
    style === "green" ? 

    <div className={`${sharedClasses}`}>
       <p className="">{text}</p>
    </div>:

    style === "red" ? 
    <div className={`${sharedClasses}`}>
        <p className="">{text}</p>
    </div>:

    style === "transparent" ? 
    <div className={`${sharedClasses} border-white border-light hover:bg-slate-200`}>
       <p className="">{text}</p>
    </div> : 
    style === "blue" ? 
    <div className={`${sharedClasses}  bg-valcri-blue`}>
       <p className="">{text}</p>
    </div> :
    null

    return urlTo ? <Link to={urlTo}>
        {button}
    </Link> : button

}


export default Button