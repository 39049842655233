import kitchenImage from "@resources/images/main-kitchen.jpeg"
import Button from "@shared/utils/button/Button"
import RoundedImage from "@shared/utils/rounded-image/RoundedImage"
import Testimonial from "@shared/utils/testimonial/Testimonial"
import useData from "hooks/useData"

function Home() {

    const {testimonials} = useData()

    return <div className="">
        <div className="first-component bg-background-dark">
            <div className="section-container h-full flex justify-center">
                <div className="flex flex-col lg:w-1/3 w-3/4 gap-5 h-full items-center justify-center">
                    <RoundedImage roundedLevel="rounded-lg">
                        <img src={kitchenImage} alt="" />
                    </RoundedImage>
                    <div className="flex flex-col">
                        <h2 className="lg:text-big text-medium font-elegant font-light text-center">TRANSFORMA TU ESPACIO</h2>
                        <p className="lg:text-medium text-paragraph text-center font-light">Expertos en reformar tu hogar</p>
                    </div>
                    <Button urlTo="/contacto" text="Solicitar presupuesto" style="blue"></Button>
                </div>
            </div>
        </div>
        <div className="component bg-background-dark">
            {testimonials.length > 0 ? <p className="text-center  w-auto font-elegant lg:text-big text-medium pb-2">Opiniones de nuestros clientes</p> : null}
            <div className="section-container  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {testimonials.map(({name, testimonial}) => 
                    <Testimonial name={name} testimonial={testimonial}></Testimonial>
                )}
            </div>
        
        </div>
        

    </div>

}

export default Home