import useData from "hooks/useData";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";



function Contact() {

    const {contact} = useData()

    return <div className="first-component bg-background-dark ">
        <div className="section-container flex flex-col gap-5 items-center">
            <p className="text-center w-auto font-elegant lg:text-big text-medium pb-8">Contacta con nosotros</p>
            <div className="flex flex-col gap-6">
                <div className="flex items-center gap-4">
                    <IoMail className="text-white text-medium"/>
                    <a className="text-white hover:underline" href={`mailto:${contact.mail}`}>{contact.mail}</a>
                </div>
                <div className="flex items-center gap-4">
                    <BsFillTelephoneFill className="text-white text-medium"></BsFillTelephoneFill>
                    <a  className="text-white hover:underline" href={`tel:+34${contact.phone}`}><span className="font-bold">+34 </span>{contact.phone}</a>
                </div>
            </div>
        </div>

    </div>
}

export default Contact