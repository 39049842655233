import Button from "../utils/button/Button"
import logo from "../../../resources/images/logo-icon.svg"
import HeaderNavegation from "./header-navegation/HeaderNavegation"
import { Link } from "react-router-dom"
import { IoIosMenu } from "react-icons/io";
import { useState } from "react";


function Header() {

    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const handleMenuSwitchClick = ()=> {
        if (!showMobileMenu) {
            setShowMobileMenu(true)
        }
    }

    return <div className="w-full backdrop-blur-md bg-opacity-55 bg-background-dark py-3 px-5 fixed top-0 left-0 z-30">
        <div className="container h-14 mx-auto flex justify-between items-center">
            <Link to={"/"}>
                <div className="flex gap-2 lg:gap-4 items-center">
                    <img src={logo} className="w-8" alt="" />
                    <h1 className="font-light capitalize text-medium-small lg:text-medium">valcri reformas</h1>
                </div>
            </Link>
            <HeaderNavegation setShowMobileMenu={setShowMobileMenu} showMobileMenu={showMobileMenu}></HeaderNavegation>
            <Button className="hidden lg:flex" urlTo="/contacto" text="Solicitar presupuesto"></Button>
            <IoIosMenu onClick={handleMenuSwitchClick} className="lg:hidden text-white text-big"></IoIosMenu>
        </div>
    </div>
}

export default Header