import mainKitchen  from "@resources/images/main-kitchen.jpeg"
import ReformSection from "@shared/reform-section/ReformSection"

import Reform, { ReformParameters } from "@shared/utils/reform/Reform"
import RoundedImage from "@shared/utils/rounded-image/RoundedImage"
import useData from "hooks/useData"


function KitchenReforms() {

    const {kitchen} = useData()

    return <ReformSection data={kitchen}></ReformSection>
}

export default KitchenReforms