import Reform from "@shared/utils/reform/Reform"
import RoundedImage from "@shared/utils/rounded-image/RoundedImage"
import { DataSectionType } from "components/contexts/DataContext"

type ReformSectionParams = {
    data : DataSectionType
}

function ReformSection({data} : ReformSectionParams) {
    return <div className="">
            <div className="first-component bg-background-dark">
                <div className="section-container flex justify-center">
                    <div className="flex md:w-1/2 justify-center items-center px-10 md:px-0 flex-col gap-5 md:gap-0 md:flex-row">
                        <div className=" w-1/2">
                            <RoundedImage roundedLevel="rounded-lg">
                                <img src={`/static/media/reforms-images/${data.info.image}`} alt="" />
                            </RoundedImage>
                        </div>
                        
                        <div className="md:w-1/2 px-5 flex flex-col justify-center gap-3">
                            <p className="text-medium font-elegant ">{data.info.title}</p>
                            <p className="font-light text-small md:text-paragraph">{data.info.description}</p>
                        </div>
                    </div>
                </div>
            </div>
           
           <div className="component bg-background-dark">
                <div className="section-container ">
                    <p className="text-center font-elegant md:text-big text-medium pb-8">Nuestras reformas</p>
                    <div className="flex flex-wrap justify-center lg:mx-auto ">
                        {data.reforms.map(({description, image, price, title, conditions}) => 
                            <div className="w-full lg:w-1/2">
                                <Reform description={description} image={image} price={price} title={title} conditions={conditions}></Reform>
                            </div>
                        )}
                    </div>
                    
    
                </div>
           </div>
            
        </div>
}

export default ReformSection