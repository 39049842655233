import OptionsLink from "@shared/utils/options-link/OptionsLink"
import { Link } from "react-router-dom"

type NavItemsParams = {
    mobile? : boolean
}

function NavItems({mobile = false} : NavItemsParams) {

    const classNames = mobile ? "flex flex-col gap-5" : "hidden lg:flex h-full items-center gap-5"

    return <nav className={classNames}>
    <Link className="" to={"/quienes-somos"}>
        <p className="hover:underline">¿Quienes somos?</p>
    </Link>
    <OptionsLink text="Reformas" navegationElements={[
            {text : "Reformas de baño", to:"/reformas-de-baño"},
            {text : "Reformas de cocina", to:"/reformas-de-cocina"},
            {text : "Reformas de piso", to:"/reformas-de-piso"}
    ]}/>
    <Link className="" to={"/contacto"}>
        <p className="hover:underline">Contacto</p>
    </Link>
</nav>
}

export default NavItems