import useData from "hooks/useData"
import ReformSection from "@shared/reform-section/ReformSection"


function BathReforms() {

    const {bath} = useData()

    return <ReformSection data={bath}></ReformSection>
}

export default BathReforms