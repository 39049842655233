import { ReformParameters } from "@shared/utils/reform/Reform"
import React from "react"

export type DataSectionInfoType = {title : string, description : string, image : string}
export type DataSectionContactType = {phone : string, mail : string}
export type DataSectionTestimonialType = {name : string, testimonial : string}



export type DataSectionType = {
    info : DataSectionInfoType
    reforms : ReformParameters[]
}

export type DataContextType = {
    data : {
        kitchen : DataSectionType,
        bath : DataSectionType,
        flat : DataSectionType,
        about : string,
        contact : DataSectionContactType,
        testimonials : DataSectionTestimonialType[]
    }
}

export const DataContext = React.createContext<DataContextType>({
    data : {
        flat : {reforms:[], info: {description : "", image : "", title : ""}},
        bath : {reforms:[], info: {description : "", image : "", title : ""}},
        kitchen : {reforms:[], info: {description : "", image : "", title : ""}},
        about : "",
        contact : {mail : "", phone : ""},
        testimonials : []
    }
})
