import useData from "hooks/useData"
import logo from "@resources/images/logo-icon.svg"

function About() {

    const {about} = useData()

    return <div className="first-component bg-background-dark">
        <div className="section-container lg:w-1/2 w-4/5 mx-auto flex flex-col items-center">
            <p className="text-center  w-auto font-elegant lg:text-big text-medium pb-8">¿Quienes somos?</p>
            <div className="flex lg:flex-row-reverse flex-col gap-7 lg:gap-0">
                <p className="lg:w-1/2 font-light">{about}</p>
                <img className="w-1/2 md:w-1/4 lg:w-auto mx-auto" src={logo} alt="" />
            </div>
        </div>
    </div>
}

export default About