import OptionsLink from "@shared/utils/options-link/OptionsLink"
import { Link } from "react-router-dom"
import MobileNav from "./mobile-nav/MobileNav"
import NavItems from "./mobile-nav/nav-items/NavItems"

type HeaderNavegationParams = {
    showMobileMenu : boolean,
    setShowMobileMenu : Function
}

function HeaderNavegation({showMobileMenu, setShowMobileMenu} : HeaderNavegationParams) {
    return <div className="h-full">
        <NavItems></NavItems>
        <MobileNav setShow={setShowMobileMenu} show={showMobileMenu}></MobileNav>
    </div>
}

export default HeaderNavegation