import React, { ReactNode } from "react"
import mainImage  from "@resources/images/main-bath.jpeg"
import RoundedImage from "../rounded-image/RoundedImage"


export type ReformParameters = {
    image : string
    title : string,
    description : string,
    price: number,
    conditions : string[]
}

function Reform({image, description, price, title, conditions} : ReformParameters) {
    return <div className="mx-auto w-full p-3 xl:h-full border-white rounded-md overflow-hidden grid md:grid-cols-2">
        <div className=" p-4 flex items-center">
            <RoundedImage roundedLevel="rounded-lg">
                <img src={`/static/media/reforms-images/${image}`} alt="" />
            </RoundedImage>
        </div>
        
        <div className="p-4 flex flex-col gap-4 lg:gap-8 justify-center">
            <p className="font-elegant text-medium-small">{title}</p>
            <p className="font-light text-small md:text-paragraph">{description}</p>
            <div className="">
                <p className="font-elegant text-medium">Desde {price}€</p>
                <div className="">
                    {conditions.length > 0 ? <p className="text-extra-small font-bold">Condiciones de esta oferta:</p> : null}
                    { conditions.map((condition) => 
                        <p className="text-extra-small">{condition}</p>
                    )}
                </div>
                
            </div>
        </div>
    </div>
}

export default Reform