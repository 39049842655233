import {Route, BrowserRouter, Routes, } from "react-router-dom"
import Home from './components/layout/home/Home';
import Header from './components/shared/header/Header';
import "./globals.css"
import KitchenReforms from "@layout/reforms/kitchen-reforms/KitchenReforms";
import BathReforms from "@layout/reforms/bath-reforms/BathReforms";
import FlatReforms from "@layout/reforms/flat-reforms/FlatReforms";
import Footer from "@shared/footer/Footer";
import { DataContext, DataContextType, DataSectionInfoType } from "components/contexts/DataContext";
import { ReformParameters } from "@shared/utils/reform/Reform";
import { useEffect, useState } from "react";
import NotFound from "@layout/NotFound";
import About from "@layout/about/About";
import ScrollToTop from "@shared/utils/scroll-to-top/ScrollToTop";
import Contact from "@layout/contact/Contact";

function App() {

  const [data, setData] = useState<DataContextType | undefined>()

  useEffect(() => {
    fetch("/static/data/data.json?" + new URLSearchParams({rv : new Date().getTime().toString()}).toString()).then(async (data) => {
      const d = await data.json()
      const dd = {bath : {reforms : d.bath.reforms as ReformParameters[], info : d.bath.info as DataSectionInfoType}, 
        flat :  {reforms : d.flat.reforms as ReformParameters[], info : d.flat.info as DataSectionInfoType}, 
        kitchen : {reforms : d.kitchen.reforms as ReformParameters[], info : d.kitchen.info as DataSectionInfoType},
        about : d.about,
        contact : {phone : d.contact.phone, mail : d.contact.mail},
        testimonials : d.testimonials
    }
      setData({data : dd} as DataContextType)
    })
  }, [])

  return (data ? 
    <div className="App font-default">
      <DataContext.Provider value={data}>
        <BrowserRouter>
          <ScrollToTop></ScrollToTop>
          <Header></Header>
      
              <Routes>
                <Route path='/'  element={<Home></Home>}></Route>
                <Route path='/reformas-de-cocina'  element={<KitchenReforms></KitchenReforms>}></Route>
                <Route path='/reformas-de-baño'  element={<BathReforms></BathReforms>}></Route>
                <Route path='/reformas-de-piso'  element={<FlatReforms></FlatReforms>}></Route>
                <Route path='/quienes-somos'  element={<About></About>}></Route>
                <Route path='/contacto'  element={<Contact></Contact>}></Route>
                <Route path="*" element={<NotFound></NotFound>}></Route>

              </Routes>
            
          <Footer></Footer>
        </BrowserRouter>
      </DataContext.Provider>
      
      
    </div>
  : null);
}

export default App;
