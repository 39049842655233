import { IoStarSharp } from "react-icons/io5";
import { FaQuoteLeft } from "react-icons/fa";


type TestimonialParams = {
    name : string,
    testimonial : string
}

function Testimonial({name, testimonial} : TestimonialParams) {
    return <div className="px-6 py-10 md:py-6 flex flex-col justify-between gap-5">
        <div className="flex gap-5">
            <FaQuoteLeft className="text-big min-w-8 text-orange-300"/>
            <p className="font-light">{testimonial}</p>
        </div>
        <div className="flex flex-col gap-5">
            <div className="h-0.5 bg-slate-200 w-full"></div>
            <div className="flex justify-between items-center">
                <p className="text-medium-small font-light capitalize">{name}</p>
                <div className="flex gap-1">
                    <IoStarSharp className="text-yellow-200" />
                    <IoStarSharp className="text-yellow-200"/>
                    <IoStarSharp className="text-yellow-200"/>
                    <IoStarSharp className="text-yellow-200"/>
                    <IoStarSharp className="text-yellow-200"/>
                </div>
            </div>
        </div>
        
    </div>
}

export default Testimonial