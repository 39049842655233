import Button from "@shared/utils/button/Button"
import OptionsLink from "@shared/utils/options-link/OptionsLink"
import React, { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import NavItems from "./nav-items/NavItems"

type MobileNavParams = {
    show : boolean,
    setShow : Function
}

function MobileNav({show, setShow} : MobileNavParams) {

    const {pathname} = useLocation()

    useEffect(() => {
        if (show) {
            setShow(false)
        }
    }, [pathname])


    const handleBlackBackgroundClick = () => {
        setShow(false)
    }

    return <div className={`flex fixed w-screen top-0 left-0 h-screen z-30 lg:hidden ${show ? "" : "pointer-events-none"}`}>

            <div className={`absolute w-full h-full top-0 left-0 bg-black transition duration-300 ${show ? "bg-opacity-50" : "bg-opacity-0"}`} onClick={handleBlackBackgroundClick}>

            </div>
            <div className={`absolute py-5 px-10 bg-valcri-blue flex flex-col justify-between duration-300 max-w-[80%] transition-right top-0 z-30 h-full w-auto ${show ? "right-0" : "right-[-20rem]"}`}>
                <div className="flex flex-col gap-5">
                    <NavItems mobile={true}></NavItems>
                    <Button urlTo="/contacto" text="Solicitar presupuesto"></Button>
                </div>
            </div>
        
    </div>
}

export default MobileNav